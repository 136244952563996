/* global window, document */

// Prism theme styles
require("prismjs/themes/prism-tomorrow.css")

// Scroll behavior for anchor links
// https://github.com/gatsbyjs/gatsby/issues/8413
const scrollTo = id => () => {
  const el = document.querySelector(id)
  if (el) return window.scrollTo(0, el.offsetTop - 20)
  return false
}

export const onRouteUpdate = ({ location: { hash } }) => {
  if (hash) {
    window.setTimeout(scrollTo(hash), 10)
  }
}
